import dayjs from 'dayjs';
import { destroyCookie, parseCookies, setCookie as setNookies } from 'nookies';

import { HOST_DOMAIN } from '../conf';

// TODO use localstorage. for now where only possible to pass cookies to pptr
// https://github.com/puppeteer/puppeteer/issues/3692#issuecomment-453186180

const USER_TOKEN = 'token';
const USER_TOKEN_PREV = 'token_prev';
const SURVEY_TOKEN = 'survey_token';
const SURVEY_COMPLETE = 'complete';
const EULA_ACCEPTED = 'eulaAccepted';
const MAX_DATE = dayjs.unix(2147483647).toDate(); // TODO review before y 2038

const userTokenOptions = {
    path: '/'
};

export const getCookie = (name: string): string => parseCookies()[name] || null;
export const setCookie = (name: string, value: string, options?: AnyObject): AnyObject =>
    setNookies(null, name, value, options);
export const delCookie = (name: string, options?: AnyObject): AnyObject => destroyCookie(null, name, options);
export const deleteAllCookies = (): void => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
};

export const setCookieWithSubdomains = (name: string, value: string, options?: AnyObject): AnyObject =>
    setNookies(null, name, value, {
        path: '/',
        domain: `.${HOST_DOMAIN}`,
        ...(options || {})
    });

export const delCookieWithSubdomains = (name: string, options?: AnyObject): AnyObject =>
    destroyCookie(null, name, {
        path: '/',
        domain: `.${HOST_DOMAIN}`,
        ...(options || {})
    });

export const getUserToken = (): string => getCookie(USER_TOKEN);

export const getPrevUserToken = (): string => getCookie(USER_TOKEN_PREV);

export const setUserToken = (value: string, isPrevUserToken?: boolean) =>
    setCookie(isPrevUserToken ? USER_TOKEN_PREV : USER_TOKEN, value, userTokenOptions);

export const delUserToken = (isPrevUserToken?: boolean) =>
    delCookie(isPrevUserToken ? USER_TOKEN_PREV : USER_TOKEN, userTokenOptions);

export const setSurveyTokenCookie = (token: string) => {
    setCookie(SURVEY_TOKEN, token, {
        path: '/',
        expires: dayjs().add(7, 'day').toDate()
    });
};

export const clearSurveyTokenCookie = () => {
    delCookie(SURVEY_TOKEN, { path: '/' });
};

export const getSurveyTokenCookie = (): string => getCookie(SURVEY_TOKEN);

export const setSurveyCompleteCookie = () => {
    setCookie(SURVEY_COMPLETE, JSON.stringify(true), {
        expires: dayjs().add(100, 'year').toDate()
    });
};

export const getSurveyCompleteCookie = (): string => getCookie(SURVEY_COMPLETE);

export const clearSurveyCompleteCookie = () => {
    delCookie(SURVEY_COMPLETE);
};

export const setEulaAcceptedCookie = () => {
    setNookies(null, EULA_ACCEPTED, JSON.stringify(true), {
        path: '/',
        expires: MAX_DATE
    });
};

export const getEulaAcceptedCookie = (): boolean => !!getCookie(EULA_ACCEPTED);
