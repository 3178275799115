import type { QuestionApi } from './mst-survey/api/queries-api';
import { AgreementApi } from './mst-survey/api/types/agreement';
import { VoidQuestionApi } from './mst-survey/api/types/common/void';
import { CsiApi } from './mst-survey/api/types/csi';
import { DatetimeApi } from './mst-survey/api/types/datetime';
import { DistributeScaleApi } from './mst-survey/api/types/distribute-scale';
import { DropdownListApi } from './mst-survey/api/types/dropdown-list';
import { EmailApi } from './mst-survey/api/types/email';
import { FewOfListApi } from './mst-survey/api/types/few-of-list';
import { FileUploadApi } from './mst-survey/api/types/file-upload';
import { GroupFreeAnswersApi } from './mst-survey/api/types/group-free-answers';
import { LongTextApi } from './mst-survey/api/types/long-text';
import { MatrixDropdownListApi } from './mst-survey/api/types/matrix-dropdown-list';
import { MatrixFewAnswersApi } from './mst-survey/api/types/matrix-few-answers';
import { MatrixRatingApi } from './mst-survey/api/types/matrix-rating';
import { MatrixSingleAnswerApi } from './mst-survey/api/types/matrix-single-answer';
import { MatrixTextAnswerApi } from './mst-survey/api/types/matrix-text-answer';
import { NameApi } from './mst-survey/api/types/name';
import { NestedListApi } from './mst-survey/api/types/nested-list';
import { NpsApi } from './mst-survey/api/types/nps';
import { NumberApi } from './mst-survey/api/types/number';
import { OneOfListApi } from './mst-survey/api/types/one-of-list';
import { PhoneApi } from './mst-survey/api/types/phone';
import { RankApi } from './mst-survey/api/types/rank';
import { ResidenceApi } from './mst-survey/api/types/residence';
import { ScaleApi } from './mst-survey/api/types/scale';
import { SelectFewImageApi } from './mst-survey/api/types/select-few-image';
import { SelectOneImageApi } from './mst-survey/api/types/select-one-image';
import { SemDiffApi } from './mst-survey/api/types/sem-diff';
import { ShortTextApi } from './mst-survey/api/types/short-text';
import { SmileApi } from './mst-survey/api/types/smile';
import { StarApi } from './mst-survey/api/types/star';
import type { ISurveyQuestionModel } from './mst-survey/question';

export enum DropdownType {
    DIFFERENT = 'different',
    COMMON = 'common'
}

export enum DropdownListType {
    SINGLE = 'single',
    MULTI = 'multi'
}

export enum QuestionType {
    DEFAULT = 0, // during creation, type select

    ONE_OF_LIST = 4,
    FEW_OF_LIST = 6,

    SELECT_ONE_IMAGE = 24,
    SELECT_FEW_IMAGE = 25,

    DROPDOWN_LIST = 15,
    SCALE = 9,
    STAR = 14,
    SMILE = 20,
    DISTRIBUTE_SCALE = 27,
    NPS = 23,
    RANK = 18,

    MATRIX_SINGLE_ANSWER = 10,
    MATRIX_FEW_ANSWERS = 11,
    MATRIX_TEXT_ANSWER = 12,
    MATRIX_DROPDOWN_LIST = 13,
    MATRIX_RATING = 26,

    SEM_DIFF = 32,

    SHORT_TEXT = 1,
    LONG_TEXT = 2,
    NUMBER = 3,
    GROUP_FREE_ANSWERS = 33,

    FILE_UPLOAD = 16,
    RESIDENCE = 8,

    NESTED_LIST = 37,

    NAME = 28,
    EMAIL = 21,
    DATETIME = 22,
    PHONE = 29,
    AGREEMENT = 34,

    TEXT_BLOCK = 17,

    TEST_ONE_OF_LIST = 35,
    TEST_FEW_OF_LIST = 36,
    TEST_TEXT = 38,
    TEST_RANK = 39,
    CSI = 40
}

export enum AnswerType {
    SELF = 1,
    RADIO = 2,
    CHECKBOX = 3,
    SELECT = 4,
    TEXT = 5
}

export enum ResidenceType {
    COUNTRY = 'COUNTRY',
    REGION = 'REGION',
    CITY = 'CITY'
}

export const RESIDENCE_TYPE_LABEL = {
    [ResidenceType.COUNTRY]: 'Страна',
    [ResidenceType.REGION]: 'Регион',
    [ResidenceType.CITY]: 'Город'
};

export enum LogicType {
    ALWAYS = 0,
    SELECT_VARIANTS = 1,
    NOT_SELECT_VARIANTS = 2,
    SKIP = 3,
    TYPE_RESPONSE_QUOTA = 4,
    TYPE_QUESTION_QUOTA = 5,
    LINK_PARAM = 6
}

export enum LogicBoolFunc {
    OR = 'OR',
    AND = 'AND'
}

export enum LogicTransition {
    REGULAR_COMPLETE = 0,
    PAGE = 1,
    QUESTION = 2,
    DISQUAL = 3,
    PERSONAL_COMPLETE = 5,
    QUESTIONS = 6,
    PAGES = 7
}

export enum LogicAction {
    SHOW = 'SHOW',
    HIDE = 'HIDE'
}

export enum LogicTransitionType {
    TO_SURVEY = 0,
    REDIRECT_TO_WEBSITE = 1,
    TEXT = 2,
    STANDART = 3
}

export const TYPES_FILE_MAP: Record<Exclude<QuestionType, QuestionType.DEFAULT>, QuestionApi> = {
    [QuestionType.ONE_OF_LIST]: new OneOfListApi(),
    [QuestionType.FEW_OF_LIST]: new FewOfListApi(),
    [QuestionType.TEST_ONE_OF_LIST]: new OneOfListApi(),
    [QuestionType.TEST_TEXT]: new ShortTextApi(),
    [QuestionType.TEST_FEW_OF_LIST]: new FewOfListApi(),
    [QuestionType.SELECT_ONE_IMAGE]: new SelectOneImageApi(),
    [QuestionType.SELECT_FEW_IMAGE]: new SelectFewImageApi(),
    [QuestionType.DROPDOWN_LIST]: new DropdownListApi(),
    [QuestionType.STAR]: new StarApi(),
    [QuestionType.NUMBER]: new NumberApi(),
    [QuestionType.SHORT_TEXT]: new ShortTextApi(),
    [QuestionType.LONG_TEXT]: new LongTextApi(),
    [QuestionType.GROUP_FREE_ANSWERS]: new GroupFreeAnswersApi(),
    [QuestionType.EMAIL]: new EmailApi(),
    [QuestionType.AGREEMENT]: new AgreementApi(),
    [QuestionType.NPS]: new NpsApi(),
    [QuestionType.RANK]: new RankApi(),
    [QuestionType.TEST_RANK]: new RankApi(),
    [QuestionType.SCALE]: new ScaleApi(),
    [QuestionType.SEM_DIFF]: new SemDiffApi(),
    [QuestionType.DISTRIBUTE_SCALE]: new DistributeScaleApi(),
    [QuestionType.DATETIME]: new DatetimeApi(),
    [QuestionType.PHONE]: new PhoneApi(),
    [QuestionType.SMILE]: new SmileApi(),
    [QuestionType.TEXT_BLOCK]: new VoidQuestionApi(),
    [QuestionType.RESIDENCE]: new ResidenceApi(),
    [QuestionType.NESTED_LIST]: new NestedListApi(),
    [QuestionType.FILE_UPLOAD]: new FileUploadApi(),
    [QuestionType.NAME]: new NameApi(),
    [QuestionType.MATRIX_FEW_ANSWERS]: new MatrixFewAnswersApi(),
    [QuestionType.MATRIX_SINGLE_ANSWER]: new MatrixSingleAnswerApi(),
    [QuestionType.MATRIX_TEXT_ANSWER]: new MatrixTextAnswerApi(),
    [QuestionType.MATRIX_RATING]: new MatrixRatingApi(),
    [QuestionType.CSI]: new CsiApi(),
    [QuestionType.MATRIX_DROPDOWN_LIST]: new MatrixDropdownListApi()
};

export const getApiClass = (t: QuestionType): QuestionApi => {
    const ac = TYPES_FILE_MAP[t];
    if (!ac?.validate) {
        console.error('type not found', t, ac);
        return new VoidQuestionApi();
    }

    return ac;
};

export enum SurveyStatus {
    STATUS_JUST_START = 0,
    STATUS_START = 1,
    STATUS_STOP = 2,
    STATUS_DISQUAL = 3,
    STATUS_DELETED = 4
}

export enum CompletePageType {
    SUCCESS_TEXT = 'SUCCESS_TEXT',
    REDIRECT_TO_WEBSITE = 'REDIRECT_TO_WEBSITE',
    TO_SURVEY = 'TO_SURVEY',
    SURVEY_RESULTS = 'SURVEY_RESULTS',
    TEST_RESULTS = 'TEST_RESULTS'
}

export enum WelcomePageType {
    NOT = 'NOT',
    TO_SURVEY_PAGE = 'TO_SURVEY_PAGE',
    TO_SINGLE_PAGE = 'TO_SINGLE_PAGE'
}

export enum IndicatorPosition {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM'
}

export enum CurrentSection {
    AUTH = 'auth',
    START = 'start',
    BUNDLES = 'bundles',
    FINISH = 'finish',
    RESULTS = 'results',
    DISABLED = 'disabled',
    TEST_RESULT = 'testResult',
    BLOCKED = 'blocked'
}

export const QuestionsWithClickReply = new Set([
    QuestionType.ONE_OF_LIST,
    QuestionType.SELECT_ONE_IMAGE,
    QuestionType.DROPDOWN_LIST,
    QuestionType.STAR,
    QuestionType.SCALE,
    QuestionType.SMILE,
    QuestionType.TEST_ONE_OF_LIST,
    QuestionType.NPS
]);

export const withClickReply = (t: QuestionType): boolean => QuestionsWithClickReply.has(t);

export const QuestionWithoutHeader = new Set([QuestionType.AGREEMENT, QuestionType.TEXT_BLOCK]);

export const TypesWithScore = new Set([
    QuestionType.TEST_ONE_OF_LIST,
    QuestionType.TEST_FEW_OF_LIST,
    QuestionType.TEST_TEXT,
    QuestionType.TEST_RANK
]);

export const filterWithScore = (questions): Array<any> => questions.filter(({ type }) => TypesWithScore.has(type));

export enum TestCheckStatus {
    NOT_CHECKED = 'NotChecked',
    VALID = 'valid',
    INVALID = 'invalid'
}

export const HIDDEN_ON_PRINT = new Set([
    QuestionType.MATRIX_DROPDOWN_LIST,
    QuestionType.MATRIX_TEXT_ANSWER,
    QuestionType.FILE_UPLOAD,
    QuestionType.GROUP_FREE_ANSWERS,
    QuestionType.NUMBER,
    QuestionType.NAME,
    QuestionType.EMAIL,
    QuestionType.DATETIME,
    QuestionType.PHONE
]);

export const hideOnPrint = (t: QuestionType): boolean => HIDDEN_ON_PRINT.has(t);

const fontUrlTpl =
    'https://fonts.googleapis.com/css?family=${family}:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap&subset=cyrillic';

const makeUrl = (family: string): string => fontUrlTpl.replace(/ /gi, '+').replace('${family}', family);

export const FONTS = {
    Montserrat: makeUrl('Montserrat'),
    Arial: null,
    Georgia: null,
    Helvetica: null,
    Tahoma: null,
    'Times New Roman': null,
    'Trebuchet MS': null,
    Verdana: null,
    Lora: makeUrl('Lora'),
    'PT Serif Caption': makeUrl('PT Serif Caption'),
    'PT Serif': makeUrl('PT Serif'),
    'EB Garamond': makeUrl('EB Garamond'),
    Roboto: makeUrl('Roboto')
};

export const TYPES_NAMES: { [type: string]: string } = {
    [QuestionType.ONE_OF_LIST]: 'Выбор одного',
    [QuestionType.FEW_OF_LIST]: 'Выбор нескольких',
    [QuestionType.SELECT_ONE_IMAGE]: 'Выбор изображения',
    [QuestionType.SELECT_FEW_IMAGE]: 'Выбор нескольких изображений',
    [QuestionType.DROPDOWN_LIST]: 'Выпадающий список',
    [QuestionType.SCALE]: 'Шкала',
    [QuestionType.STAR]: 'Звездный рейтинг',
    [QuestionType.SMILE]: 'Смайл рейтинг',
    [QuestionType.DISTRIBUTE_SCALE]: 'Распределительная шкала',
    [QuestionType.NPS]: 'NPS',
    [QuestionType.RANK]: 'Ранжирование',
    [QuestionType.MATRIX_FEW_ANSWERS]: 'Матрица - выбор нескольких',
    [QuestionType.MATRIX_SINGLE_ANSWER]: 'Матрица - выбор одного',
    [QuestionType.MATRIX_RATING]: 'Матрица рейтинг',
    [QuestionType.CSI]: 'CSI',
    [QuestionType.MATRIX_TEXT_ANSWER]: 'Матрица - текст',
    [QuestionType.MATRIX_DROPDOWN_LIST]: 'Матрица - выпадающий',
    [QuestionType.SEM_DIFF]: 'Семантический дифференциал',
    [QuestionType.SHORT_TEXT]: 'Короткий текст',
    [QuestionType.LONG_TEXT]: 'Длинный текст',
    [QuestionType.NUMBER]: 'Число',
    [QuestionType.GROUP_FREE_ANSWERS]: 'Группа свободных ответов',
    [QuestionType.FILE_UPLOAD]: 'Загрузка файла',
    [QuestionType.RESIDENCE]: 'Место жительства',
    [QuestionType.NESTED_LIST]: 'Вложенные списки',
    [QuestionType.NAME]: 'Имя',
    [QuestionType.EMAIL]: 'E-mail',
    [QuestionType.DATETIME]: 'Дата/время',
    [QuestionType.PHONE]: 'Телефон',
    [QuestionType.AGREEMENT]: 'Галочка согласие',
    [QuestionType.TEXT_BLOCK]: 'Текст между вопросами',
    [QuestionType.TEST_ONE_OF_LIST]: 'Один из списка',
    [QuestionType.TEST_FEW_OF_LIST]: 'Несколько из списка',
    [QuestionType.TEST_TEXT]: 'Текст ответа',
    [QuestionType.TEST_RANK]: 'Последовательность'
};

export const QuestionsWithImages = new Set([QuestionType.SELECT_FEW_IMAGE, QuestionType.SELECT_ONE_IMAGE]);

export const withImages = (t: QuestionType): boolean => QuestionsWithImages.has(t);

export const filterWithImages = (qs: Array<ISurveyQuestionModel>): Array<ISurveyQuestionModel> =>
    qs.filter(({ type }) => withImages(type));

export enum DisallowRefillingType {
    ALL_TIME = 'ALL_TIME',
    PERIOD = 'PERIOD'
}

export enum DisallowRefillingTimeType {
    HOUR = 'HOUR',
    DAY = 'DAY'
}

export const QuestionsWithQuota = new Set([
    QuestionType.ONE_OF_LIST,
    QuestionType.FEW_OF_LIST,
    QuestionType.DROPDOWN_LIST
]);
